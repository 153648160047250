body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.btn {
  border-color: #bbb !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.align-center {
  text-align: center;
}
.padded {
  padding: 5px 10px;
}
.hide {
  display: none;
}
.warning {
  font-weight: bold;
  color: red;
}
.form-control {
  display: inline-block;
}
.red {
  color: red;
}
.hide {
  display: none;
}
.text-muted {
  color: #999;
}
label {
  display: block;
  font-weight: normal;
}
table {
  font-family: 'Lucida Console';
  border-spacing: 1px;
  border-collapse: collapse;
  font-size: 12px;
  width: 100%;
}
table th {
  background: #369;
  color: #fff;
}
table th.cellLast {
  border-right: solid 1px #fff;
}
table td {
  border-bottom: solid 1px #fff;
  background: #f6f6f6;
}
table td.cellLast {
  border-right: solid 1px #369;
}
th,
td {
  padding: 0;
  font-weight: normal;
  text-align: right;
}
th span,
td span {
  padding: 5px;
  display: inline-block;
}
th span span,
td span span {
  display: none;
}
th.align-left,
td.align-left {
  text-align: left;
  padding-left: 5px;
}
th.cellHidden,
td.cellHidden {
  display: none;
  width: 0;
  visibility: hidden;
}
th.cellHidden span,
td.cellHidden span {
  width: 0;
  display: none;
  padding: 0;
}
td .glyphicon {
  color: #ccc;
  cursor: pointer;
  text-decoration: none;
}
td .glyphicon.glyphicon-trash {
  color: red;
}
td .glyphicon.glyphicon-comment.on {
  color: #369;
  cursor: pointer;
}
input[type=number] {
  -moz-appearance: textfield;
}
.popover textarea {
  width: 250px;
  height: 100px;
  padding: 5px;
}
.nowrap {
  white-space: nowrap;
}
.input-xl {
  font-size: 28px;
  height: 66px;
}
#JuggsSettingsContainer .shot-data-view .panel aside p {
  height: 100px;
}
#JuggsSettingsContainer .shot-data-view .panel input {
  max-width: 100px;
}
#JuggsSettingsContainer #juggs-settings-wheel aside p {
  height: 60px;
}
#JuggsSettingsContainer #juggs-settings-wheel aside label {
  width: 100px;
  display: inline-block;
  font-size: 24px;
}
#window-bar {
  float: left;
  width: 100%;
  background: #ddd;
  -webkit-app-region: drag;
  /* enables user to click and drag window */
}
#window-bar .btn {
  -webkit-app-region: no-drag;
}
#window-bar h1 {
  color: #666;
  font-size: 14px;
  margin: 0 0 0 0;
  padding: 10px 0 10px 5px;
  float: left;
  font-weight: bold;
}
#window-bar div {
  float: right;
  margin-right: 10px;
  margin-top: 5px;
}
#AppHeader {
  position: relative;
  background: #000;
  color: #fff;
  border-bottom: solid 1px #369;
  float: left;
  width: 100%;
}
#AppHeader ul {
  margin-top: 10px;
  padding-left: 10px;
  margin-left: 0;
}
#AppHeader ul li {
  display: inline-block;
  margin-right: 10px;
}
#AppHeader ul li a {
  text-decoration: none;
  color: #fff;
  padding: 5px 10px;
  background: #666;
  color: #ddd;
  border-radius: 2px;
}
#AppHeader ul li a:hover {
  background: #555;
}
#AppHeader ul li.active a {
  background: yellow;
  color: #333;
}
#AppHeader div {
  position: absolute;
  right: 15px;
  top: 10px;
  color: #999;
}
#AppHeader div span {
  color: #ff0;
  margin-right: 10px;
}
#console {
  font-family: 'Lucida Console';
  font-size: 12px;
  padding: 1em;
  background: #000;
  color: #f6f6f6;
  overflow-y: scroll;
  height: 100px;
  position: absolute;
  bottom: 0;
  width: 100%;
  position: fixed;
}
#console ul {
  margin: 0;
  padding: 0;
}
#console ul li {
  margin-left: 5px;
}
.sensor-container {
  clear: both;
  display: none;
}
.sensor-container .sensor-header .buttons-right {
  position: absolute;
  right: 15px;
  top: 5px;
}
.sensor-container .shot-table-header {
  margin-right: 15px;
}
.sensor-container .shot-table-container {
  margin-right: 0;
}
.sensor-container.no-viz .shot-viz {
  display: none;
}
.sensor-container .w32 {
  width: 32px;
}
.sensor-container .shot-table-container {
  height: 500px;
  overflow-y: scroll;
  position: relative;
}
.sensor-container .shot-table-container .shot-table > tbody > tr:first-child > td {
  font-size: 14px;
  height: 50px;
  background: #fff;
  border-bottom: solid 1px #f6f6f6;
}
.sensor-container .shot-table-container .shot-viz {
  position: fixed;
  right: 16px;
  top: 0;
  bottom: 0;
  background: #000;
  border-left: solid 2px #369;
  display: none;
}
.sensor-container .shot-table-container .shot-viz.visible {
  width: 60%;
}
.sensor-container .shot-table-container .shot-viz.visible div div {
  display: block;
}
.sensor-container .shot-table-container .shot-viz div {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  background: #e3e3e3;
}
.sensor-container .shot-table-container .shot-viz div div {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  display: none;
  padding: 10px;
}
.sensor-container .shot-table-container .shot-viz button {
  position: absolute;
  left: -18px;
  top: 50px;
  background: #e3e3e3;
  border: solid 2px #369;
  border-right: solid 1px #e3e3e3;
  padding: 2px;
  border-radius: 0;
  cursor: pointer;
}
.sensor-container .shot-data-view {
  background: #369;
  overflow-y: scroll;
}
.sensor-container .shot-data-view h3 {
  color: #fff;
  padding: 15px 0 15px 15px;
  margin: 0;
}
.sensor-container .shot-data-view .panel {
  border: none;
  border-radius: 2px;
  margin-top: 8px;
}
.sensor-container .shot-data-view .panel .panel-body {
  padding: 5px;
}
.sensor-container .shot-data-view .panel .panel-heading {
  font-size: 24px;
  padding: 0 15px;
  text-align: center;
}
.sensor-container .shot-data-view .panel aside {
  position: relative;
  border-left: 24px solid #369;
  padding-left: 5px;
  background: #e3e3e3;
}
.sensor-container .shot-data-view .panel aside h3 {
  direction: ltr;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 10px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #fff;
  padding: 5px 10px;
  line-height: 16px;
  transform-origin: 0 0;
  transform: rotate(90deg);
}
.sensor-container .shot-data-view .panel aside p {
  font-size: 32px;
  height: 80px;
  color: #aaa;
  padding: 15px 0 0 5px;
}
.sensor-container .shot-data-view .panel aside p span {
  color: #000;
}
/* responsive */
.mobileOnly {
  display: none;
}
@media only screen and (max-width: 1300px) {
  .hideTablet {
    display: none;
  }
}
@media only screen and (max-width: 1000px) {
  .hideMobile {
    display: none !important;
    width: 0;
    visibility: hidden !important;
  }
  .mobileOnly {
    display: inline !important;
    visibility: visible !important;
  }
  span.mobileOnly {
    display: inline !important;
    visibility: visible !important;
  }
  div.mobileOnly {
    display: block !important;
    visibility: visible !important;
  }
  #console {
    padding: 0.25em;
    height: 20px;
  }
}
.nmpd-wrapper {
  display: none;
}
.nmpd-target {
  cursor: pointer;
}
.nmpd-grid {
  position: absolute;
  left: 50px;
  top: 50px;
  z-index: 5000;
  -khtml-user-select: none;
  border-radius: 10px;
  padding: 10px;
  width: initial;
}
.nmpd-overlay {
  z-index: 4999;
}
input.nmpd-display {
  text-align: right;
}

/*
* Deleted content 
*/

#conditionFormDiv {
  background-color: #ffffff;
  border: 1px solid #bbbaba;
  position: fixed;
  width: 600px;
  height: 480px;
  top: 30%;
  left: 50%;
  margin-top: -80px; /* Negative half of height. */
  margin-left: -250px; /* Negative half of width. */
  visibility: hidden;
  border-radius: 10px;
}

#conditionFormHeader {
  width: 100%;
  height: 50px;
  color: white;
  background-color: #286197;
  border-radius: 10px 10px 0 0;
}

#conditionFormHeader h4 {
  padding: 8px;
  margin-top: 0;
}

#conditionForm label,
input,
select {
  margin: 5px 0px 0px 10px;
}

.conditionSelect {
  width: 21%;
}

#saveConditionBtn {
  margin: 10px;
}

#compactionDiv input {
  width: 25%;
  float: left;
}

#moistureDiv input {
  width: 25%;
  float: left;
}

#stimpDiv input {
  width: 25%;
  float: left;
}

#conditionAvg {
  float: left;
  width: 15%;
  padding-left: 5px;
  padding-top: 5px;
}

#conditionSettingsBtns {
  width: 100%;
  float: left;
}

.radio-select {
  display: flex;
  align-items: center;
}

.radio-select input[type="radio"] {
  margin: 5px;
}

#clearConditionBtn {
  margin-left: 340px;
}
.note-box {
    position: absolute;
    background: white;
    padding: 18px;
    z-index: 100;
    border: 1px solid #ccc;
    box-shadow: 4px 4px 4px #ccc;
}
.note-box textarea {
  font-family: Arial, Helvetica, sans-serif;
  letter-spacing: 0.05em;
  font-size: 10pt;
}
